import * as React from "react"

import Layout from "../components/global/layout/Layout"
import Seo from "../components/global/seo/Seo"
import DynamicComponent from "../components/DynamicComponent"
import useStoryblok from "../lib/storyblok"
import LanguageProvider from "../i18n/LanguageProvider"
import { BackgroundKeyToColor } from "../utils/datasources"

import "bootstrap/dist/css/bootstrap.min.css";
import StickyBottle from "../components/widgets/stickyBottle/StickyBottle"

const AnimationPage = ({ pageContext, location }) => { 
  let story = pageContext.story !== undefined && pageContext.story && pageContext.story !== "" ? pageContext.story : {}
  story = useStoryblok(story, location)
  
  let components = []
  if(story.content.body) {
    components = story.content.body.map(blok => {
      if(["media_listing_panel","story_listing_panel","event_listing_panel", "featured_events_panel"].indexOf(blok.component) !== -1) {
        // filter results for locale
        const data = {
          mediaListingData: [],
          storyListingData: [],
          eventListingData: [],
          featuredEventListingData: [],
          topicData: []
        }
        if(pageContext.mediaListingData) {
          pageContext.mediaListingData.forEach((cardData)=>{
            data.mediaListingData.push(cardData)
          })
        }
        if(pageContext.storyListingData) {
          pageContext.storyListingData.forEach((cardData)=>{
            data.storyListingData.push(cardData)
          })
        }
        if(pageContext.eventListingData) {
          pageContext.eventListingData.forEach((cardData)=>{
            data.eventListingData.push(cardData)
          })
        }
        if(pageContext.featuredEventListingData) {
          pageContext.featuredEventListingData.forEach((cardData)=>{
            data.featuredEventListingData.push(cardData)
          })
        }
        if(pageContext.topicData) {
          pageContext.topicData.forEach((topic)=>{
            data.topicData.push(topic)
          })
        }
        return (<DynamicComponent blok={blok} key={blok._uid} data={data} locale={pageContext.locale} />)
      }
      return (<DynamicComponent blok={blok} key={blok._uid} locale={pageContext.locale} />)
    })
  }
 
  return (
    <LanguageProvider locale={pageContext.locale}>
      <Layout navigation={pageContext.navigation} locale={pageContext.locale} settings={pageContext.globalSettings} location={location}>
        {story.content.background_color ? (
          <style>{`body {
            background-color: ${BackgroundKeyToColor(story.content.background_color)};
          }`}</style>
        ) : null}
        <Seo blok={story && story.content ? story : null} locale={pageContext.locale} location={location} />
        <div style={{maxWidth: 720 + 'px', margin: "auto", position: "relative"}}>
          <StickyBottle/>
          { components }
        </div>
      </Layout>
    </LanguageProvider>
)}
 
export default AnimationPage